import React, { useState, useRef } from 'react';
import '../src/css/submit.css';

const Text = () => {
    const [text, setText] = useState('');
    const fileInputRef = useRef(null); // Reference to the file input

    const handleChange = (event) => {
        setText(event.target.value);
    };

    const downloadTextFile = () => {
        const blob = new Blob([text], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'text.txt';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'text/plain') {
            const reader = new FileReader();
            reader.onload = (e) => setText(e.target.result);
            reader.readAsText(file);
        }
    };

    const clearText = () => {
        setText('');
    };

    const totalWords = text.trim() ? text.trim().split(/\s+/).length : 0;
    const totalCharacters = text.length;

    return (
        <>
            <div>
                <textarea
                    value={text}
                    onChange={handleChange}
                    rows="10"
                    cols="50"
                    placeholder="Write your text here..."
                    className='text-editor'
                />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <button onClick={downloadTextFile} className='edit-button'>Download</button>
                    <button 
                        onClick={() => fileInputRef.current.click()} 
                        className='edit-button'
                    >
                        Upload
                    </button>
                    <input 
                        type="file" 
                        accept=".txt" 
                        onChange={handleFileUpload} 
                        ref={fileInputRef} 
                        style={{ display: 'none' }} // Hide the file input
                    />
                    <button onClick={clearText} className='edit-button'>New File</button>
                    <button className='edit-button'>Total Words: {totalWords}</button>
                    <button className='edit-button'>Total Characters: {totalCharacters}</button>
                </div>
            </div>
        </>
    );
};

export default Text;
