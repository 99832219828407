import React, { useEffect, useState } from 'react';
import '../css/submit.css'
import { Helmet } from 'react-helmet';
import { Editor } from '@tinymce/tinymce-react';
import { ToastContainer, toast } from 'react-toastify';

export default function ManageJob() {
    const [jobsList, setJobsList] = useState([])
    const [data, setDate] = useState()
    const [pageNumber, setPageNumber] = useState(1)
    const [editJob, setEditJob] = useState()
    const [companyList, setCompanyList] = useState([]);
    const [loading, setLoading] = useState(false)
    const [jobInfo, setJobInfo] = useState({
        title: '',
        location: '',
        remote: false,
        status: false,
        expiryDate : '',
        type: '',
        description: '',
        companyId: '',
        application: '',
    });

    const handleRowClick = (job) => {
        setJobInfo({
            title: job.title ?? '',
            location: job.location ?? '',
            remote: job.remote === 'Remote',
            type: job.job_type,
            expiryDate: job.expiry_date,
            description: job.description,
            companyId: job.company_id,
            status: job.status == 'active' ? false : true,
            application: job?.application_preference,
        });
        setEditJob(job)
    };

    const fetchJobs = async (page) => {
        var bearertoken = localStorage.getItem("Logtoken")
        setPageNumber(page)
        try {
            const response = await fetch(`https://api.buzzinguniverse.iqspark.org/public/api/get-my-jobs?page=${page}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + bearertoken,
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            if (data?.status == 200) {
                setJobsList(prevJobs => [...prevJobs, ...data?.data?.data])
                setDate(data?.data)
            }
            console.log(data, data?.data, data?.data?.data)
        } catch (error) {
            console.error('Error fetching groups:', error);
        }
    };

    const fetchCompaniese = async () => {
        var bearertoken = localStorage.getItem("Logtoken")

        console.log('BBBBBBBBBBBBBBBBBBBBBBBBBB', bearertoken)
        try {
            const response = await fetch(`https://api.buzzinguniverse.iqspark.org/public/api/get-my-companies`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + bearertoken,
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            if (data?.status == 200) {
                setCompanyList(data?.data)
            }
        } catch (error) {
            console.error('Error fetching groups:', error);
        }
    };


    useEffect(() => {
        void fetchJobs(1)
        void fetchCompaniese()
    }, [])

    const handleJobChange = (e) => {
        const { name, value, type, checked } = e.target;
        setJobInfo((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };
    const handleEditorChange = (content) => {
        setJobInfo((prev) => ({
            ...prev,
            description: content,
        }));
    };
    const validateForm = () => {
        const { title, location, type, description, application, companyId } = jobInfo;
        if (!title || !location || !type || !description || !application || !companyId) {
            alert("Please fill in all the fields for job");
            return false;
        }
        return true;
    };
    console.log('PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP', jobInfo)

    const handleSubmit = async (e) => {
        e.preventDefault();
        var bearertoken = localStorage.getItem("Logtoken")
        setLoading(true)
        const formDataToSubmit = new FormData();
        formDataToSubmit.append('status', 'active');
        formDataToSubmit.append('description', jobInfo?.description);
        formDataToSubmit.append('location', jobInfo?.location);
        formDataToSubmit.append('remote', jobInfo?.remote ? '1' : '0');
        formDataToSubmit.append('job_type', jobInfo?.type);
        formDataToSubmit.append('application_email', jobInfo?.application);
        formDataToSubmit.append('expiry_date', jobInfo?.expiry_date)
        formDataToSubmit.append('company_id', jobInfo.companyId);
        formDataToSubmit.append('status', jobInfo?.status ? 'pending' : 'active')

        for (let [key, value] of formDataToSubmit.entries()) {
            console.log(`${key}: ${value}`);
        }
        if (validateForm) {
            try {
                const response = await fetch(`https://api.buzzinguniverse.iqspark.org/public/api/update-job-status/${editJob?.id}`, {
                    method: 'POST',
                    headers: {
                        Authorization: 'Bearer ' + bearertoken,
                        'Accept': 'application/json',
                    },
                    body: formDataToSubmit, // Sending as form-data
                });
                setLoading(false)
                const data = await response.json();

                if (data.status == 200) {
                    toast.success("Job updated Successfully!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    window.location.reload();
                }
                else {
                    throw new Error('Failed to update job');
                }
            } catch (error) {
                setLoading(false)
                console.error('Error:', error);
                throw new Error(JSON.stringify(error));
            }
        }
    };

    return (
        <>
            <ToastContainer />
            {
                editJob != null ?

                    <>

                        <Helmet>
                            <link
                                rel="stylesheet"
                                href="https://buzzinguniverse.iqspark.org/css_file/submit.css"
                            />
                        </Helmet>
                        <div id="content" className="site-content">
                            <div id="primary" className="content-area">
                                <div className="layout social">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div style={{ margin: "0 auto" }} className="col-lg-8 col-main">
                                                <main id="main" className="main-content">
                                                    <article id="post-59" className="post-59 page type-page status-publish hentry beehive-post">
                                                        <div className="entry-content clearfix">
                                                            <form action="/post-a-job/" method="post" id="submit-job-form" className="job-manager-form" enctype="multipart/form-data" onSubmit={handleSubmit} >
                                                                <input type="hidden" id="_wpjm_nonce" name="_wpjm_nonce" value="1dca455611" />
                                                                <input type="hidden" name="_wp_http_referer" value="/post-a-job/" />
                                                                <div className="block-title">
                                                                    <h3>Job details</h3>
                                                                </div>

                                                                <fieldset className="fieldset-job_title fieldset-type-text">
                                                                    <label htmlFor="job_title">Job Title</label>
                                                                    <div className="field required-field">
                                                                        <input
                                                                            type="text"
                                                                            className="input-text text-field"
                                                                            name="title"
                                                                            id="job_title"
                                                                            placeholder="Enter the job title"
                                                                            value={jobInfo.title}
                                                                            onChange={handleJobChange}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </fieldset>
                                                                <fieldset className="fieldset-job_location fieldset-type-text">
                                                                    <label htmlFor="job_location">Location <small>(optional)</small></label>
                                                                    <div className="field">
                                                                        <input
                                                                            type="text"
                                                                            className="input-text text-field"
                                                                            name="location"
                                                                            id="job_location"
                                                                            placeholder="e.g. &quot;London&quot;"
                                                                            value={jobInfo.location}
                                                                            onChange={handleJobChange}
                                                                        />
                                                                        <small className="description">Leave this blank if the location is not important</small>
                                                                    </div>
                                                                </fieldset>
                                                                <fieldset className="fieldset-remote_position fieldset-type-checkbox">
                                                                    <label htmlFor="remote_position">Remote Position <small>(optional)</small></label>
                                                                    <div className="field" style={{ marginTop: '1.5%' }}>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="input-checkbox"
                                                                            name="remote"
                                                                            id="remote_position"
                                                                            checked={jobInfo.remote}
                                                                            onChange={handleJobChange}
                                                                        />
                                                                        <small className="description">Select if this is a remote position.</small>
                                                                    </div>
                                                                </fieldset>
                                                                <fieldset className="fieldset-job_type fieldset-type-term-select">
                                                                    <label htmlFor="job_type">Job type</label>
                                                                    <div className="field required-field">
                                                                        <select
                                                                            name="type"
                                                                            id="job_type"
                                                                            className="postform"
                                                                            value={jobInfo.type}
                                                                            onChange={handleJobChange}
                                                                        >
                                                                            <option value="Freelance">Freelance</option>
                                                                            <option value="Full Time">Full Time</option>
                                                                            <option value="Internship">Internship</option>
                                                                            <option value="Part Time">Part Time</option>
                                                                            <option value="Temporary">Temporary</option>
                                                                        </select>
                                                                    </div>
                                                                </fieldset>
                                                                <fieldset className="fieldset-job_description fieldset-type-wp-editor">
                                                                    <label htmlFor="job_description">Description</label>
                                                                    <div className="field required-field">
                                                                        <Editor
                                                                            apiKey="6n6085y2zxnpx1z72tdi0n1cqo5x7zl2zv3z5xtxikdg5oo6" // Get your free API key at https://www.tiny.cloud/
                                                                            value={jobInfo.description}
                                                                            init={{
                                                                                height: 400,
                                                                                menubar: false,
                                                                                plugins: [
                                                                                    'advlist autolink lists link image charmap print preview anchor',
                                                                                    'searchreplace visualblocks code fullscreen',
                                                                                    'insertdatetime media table paste code help wordcount'
                                                                                ],
                                                                                toolbar:
                                                                                    'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                                                                            }}
                                                                            onEditorChange={handleEditorChange}
                                                                        />
                                                                    </div>
                                                                </fieldset>
                                                                <fieldset className="fieldset-application fieldset-type-text">
                                                                    <label htmlFor="application">Application email</label>
                                                                    <div className="field required-field">
                                                                        <input
                                                                            type="text"
                                                                            className="input-text text-field"
                                                                            name="application"
                                                                            id="application"
                                                                            placeholder="Enter an email address or website URL"
                                                                            value={jobInfo.application}
                                                                            onChange={handleJobChange}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </fieldset>
                                                                <fieldset className="fieldset-job_type fieldset-type-term-select">
                                                                    <label htmlFor="job_type">Select Company</label>
                                                                    <div className="field required-field">
                                                                        <select
                                                                            name="companyId"
                                                                            id="companyId"
                                                                            className="postform"
                                                                            value={jobInfo.companyId}
                                                                            onChange={handleJobChange}
                                                                        >
                                                                            {companyList?.map(company => (
                                                                                <option key={company.id} value={company.id}>
                                                                                    {company.name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </fieldset>
                                                                <fieldset className="fieldset-application fieldset-type-text">
                                                                    <label htmlFor="expiryDate">Expiry Date</label>
                                                                    <div className="field">
                                                                        <input
                                                                            type="date"
                                                                            className="expiry"
                                                                            name="expiryDate"
                                                                            id="expiryDate"
                                                                            placeholder="Enter expiryDate"
                                                                            value={jobInfo.expiryDate}
                                                                            onChange={handleJobChange}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </fieldset>
                                                                <fieldset className="fieldset-remote_position fieldset-type-checkbox">
                                                                    <label htmlFor="remote_position">Save As Draft</label>
                                                                    <div className="field" style={{ marginTop: '1.5%' }}>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="input-checkbox"
                                                                            name="status"
                                                                            id="remote_position"
                                                                            checked={jobInfo.status}
                                                                            onChange={handleJobChange}
                                                                        />
                                                                    </div>
                                                                </fieldset>
                                                                <div className="submit">
                                                                    <input type="hidden" name="job_manager_form" value="submit-job" />
                                                                    <input type="hidden" name="job_id" value="0" />
                                                                    <input type="hidden" name="step" value="0" />
                                                                    <input disabled={loading} type="submit" name="submit_job" className="button" value={loading ? 'Loading...' : 'Save'} />
                                                                    <span className="spinner"></span>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </article>
                                                </main>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>

                    :
                    <div id="content" className="site-content">
                        <Helmet>
                            <link
                                rel="stylesheet"
                                href="https://buzzinguniverse.iqspark.org/css_file/submit.css"
                            />
                        </Helmet>
                        <div id="primary" className="content-area">
                            <div className="layout social">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div style={{ margin: "0 auto" }} className="col-lg-8 col-main">
                                            <main id="main" className="main-content">
                                                <article id="post-59" className="post-59 page type-page status-publish hentry beehive-post">
                                                    <div className="entry-content clearfix">
                                                        <div className="manage-job-container">
                                                            <table className="job-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Title</th>
                                                                        <th>Filled?</th>
                                                                        <th>Status</th>
                                                                        <th>Date Posted</th>
                                                                        <th>Listing Expires</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        jobsList?.map((item, index) => {
                                                                            return (
                                                                                <tr onClick={() => handleRowClick(item)} key={index} style={{ cursor: 'pointer' }}>
                                                                                    <td>{item?.title}</td>
                                                                                    <td>-</td>
                                                                                    <td>{item?.status ?? 'Posted'}</td>
                                                                                    <td>{item?.created_at}</td>
                                                                                    <td>{item?.expiry_date}</td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                            <div style={{ width: '100%', marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                {data?.next_page_url != null &&
                                                                    <input disabled={loading} onClick={() => fetchJobs(pageNumber + 1)} type="button" name="fetch-records" className="button" value={loading ? 'Loading...' : 'Show More'} />
                                                                }
                                                            </div>
                                                            {/* Add more rows as needed */}


                                                        </div>
                                                    </div>
                                                </article>
                                            </main>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>


    );
}