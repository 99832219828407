import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Editor } from '@tinymce/tinymce-react';
import { ToastContainer, toast } from 'react-toastify';

const Submitarticle = () => {
  const [formData, setFormData] = useState({
    title: '',
    category: '-1',
    content: '',
    featuredImage: null,
    tags: ''
  });
  const [loading, setLoading] = useState(false)

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleEditorChange = (content) => {
    setFormData(prev => ({
      ...prev,
      content
    }));
  };

  const handleFileChange = (e, fieldName) => {
    const file = e.target.files[0];
    setFormData(prev => ({
      ...prev,
      [fieldName]: file
    }));
  };

  const handleSubmit = async(e) => {
    e.preventDefault();

    if (!formData.title ||
      formData.category === '-1' ||
      !formData.content ||
      !formData.featuredImage ||
      !formData.tags) {
      alert('Please fill in all required fields');
      return;
    }
    const formDataToSubmit = new FormData();
    formDataToSubmit.append('title', formData?.title);
    formDataToSubmit.append('category_id', '1');
    formDataToSubmit.append('description', formData?.content);
    formDataToSubmit.append('feature_image', formData?.featuredImage)

    const tags = formData?.tags ? formData?.tags.split(',') : [];
    var bearertoken = localStorage.getItem("Logtoken")

    tags.forEach((tag, index) => {
      formDataToSubmit.append(`tags[${index}]`, tag.trim());
    });

    try {
      const response = await fetch('https://api.buzzinguniverse.iqspark.org/public/api/submit-article', {
        method: 'POST',
        headers: {
            Authorization: 'Bearer '+bearertoken, 
          'Accept': 'application/json',
        },
        body: formDataToSubmit,
      });
      
      setLoading(false)
      const data = await response.json();
      if(data.status == 200 || data?.success)
      {
        toast.success("article submitted Successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          window.location.reload();
      }
      else {
        throw new Error('Failed to post Article');
      }
    } catch (error) {
        setLoading(false)
        console.error('Error:', error);
        throw new Error(JSON.stringify(error));
    }
  };

  return (
    <>
    <ToastContainer />
      <Helmet>
        <link
          rel="stylesheet"
          href="https://buzzinguniverse.iqspark.org/css_file/submitarticle.css"
        />
      </Helmet>
      <div style={{ backgroundColor: "#000" }} id="beehive-page" className="site">
        <div id="content" className="site-content">
          <div data-elementor-type="wp-page" data-elementor-id="4795" className="elementor elementor-4795" data-elementor-post-type="page">
            <section className="elementor-section elementor-top-section elementor-element elementor-element-06f419f elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="06f419f" data-element_type="section">
              <div className="elementor-container elementor-column-gap-default">
                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9a5a920" data-id="9a5a920" data-element_type="column">
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div className="elementor-element elementor-element-05bc150 elementor-widget elementor-widget-shortcode" data-id="05bc150" data-element_type="widget" data-widget_type="shortcode.default">
                      <div className="elementor-widget-container">
                        <div className="elementor-shortcode">
                          <form
                            className="wpuf-form-add wpuf-form-layout1 wpuf-theme-style"
                            onSubmit={handleSubmit}
                          >
                            <ul className="wpuf-form form-label-above">
                              <li className="wpuf-el tags" data-label="Tags">
                                <div className="wpuf-label">
                                  <label htmlFor="tags_4781">Title <span className="required">*</span>
                                  </label>
                                </div>
                                <div className="wpuf-fields">
                                  <input
                                    className="textfield wpuf_tags_4781"
                                    id="tags_4781"
                                    type="text"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleInputChange}
                                    placeholder="Enter title"
                                  />
                                </div>
                              </li>
                              <li className="wpuf-el category" data-label="Category">
                                <div className="wpuf-label">
                                  <label htmlFor="category_4781">Category <span className="required">*</span>
                                  </label>
                                </div>
                                <div className="wpuf-fields wpuf_category_select_4783_4781">
                                  <select
                                    name="category"
                                    value={formData.category}
                                    onChange={handleInputChange}
                                    data-required="yes"
                                    data-type="select"
                                    id="category"
                                    className="category wpuf_category_4781"
                                  >
                                    <option value="-1">– select –</option>
                                    <option className="level-0" value="126">Business</option>
                                    <option className="level-0" value="43">Fashion</option>
                                    <option className="level-0" value="127">Finance</option>
                                    <option className="level-0" value="44">Food</option>
                                    <option className="level-0" value="45">Fun</option>
                                    <option className="level-0" value="46">Health</option>
                                    <option className="level-0" value="47">Hobbies</option>
                                    <option className="level-0" value="108">Jobs</option>
                                    <option className="level-0" value="48">Lifestyle</option>
                                    <option className="level-0" value="150">Marketing</option>
                                    <option className="level-0" value="49">Sports</option>
                                    <option className="level-0" value="50">Technology</option>
                                    <option className="level-0" value="51">Traveling</option>
                                    <option className="level-0" value="1">Uncategorized</option>
                                    <option className="level-0" value="128">Weather</option>
                                  </select>
                                  <span className="wpuf-wordlimit-message wpuf-help"></span>
                                  <span className="wpuf-help">Select a category for your article</span>
                                </div>
                              </li>
                              <li className="wpuf-el post_content field-size-large" data-label="Article Description">
                                <div className="wpuf-label">
                                  <label htmlFor="post_content_4781">Article Description <span className="required">*</span>
                                  </label>
                                </div>
                                <div className="wpuf-fields wpuf-rich-validation wpuf_post_content_4781" data-type="rich" data-required="yes" data-id="post_content_4781" data-name="post_content">
                                  <div id="wp-post_content_4781-wrap" className="wp-core-ui wp-editor-wrap tmce-active">
                                    <Editor
                                      apiKey="6n6085y2zxnpx1z72tdi0n1cqo5x7zl2zv3z5xtxikdg5oo6" // Get your free API key at https://www.tiny.cloud/
                                      value={formData.content}
                                      init={{
                                        height: 400,
                                        menubar: false,
                                        plugins: [
                                          'advlist autolink lists link image charmap print preview anchor',
                                          'searchreplace visualblocks code fullscreen',
                                          'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar:
                                          'undo redo | formatselect | bold italic backcolor | \
                                            alignleft aligncenter alignright alignjustify | \
                                            bullist numlist outdent indent | removeformat | help'
                                      }}
                                      onEditorChange={handleEditorChange}
                                    />
                                  </div>
                                  <span className="wpuf-help">Write the full description of your article</span>
                                </div>
                              </li>
                              <li className="wpuf-el featured_image" data-label="Featured Image">
                                <div className="wpuf-label">
                                  <label htmlFor="featured_image_4781">Featured Image <span className="required">*</span>
                                  </label>
                                </div>
                                <div className="wpuf-fields">
                                  <div id="wpuf-featured_image-4781-upload-container">
                                    <div className="wpuf-file-warning"></div>
                                    <div className="wpuf-attachment-upload-filelist" data-type="file" data-required="yes">
                                      <button
                                        type="button"
                                        id="wpuf-featured_image-4781-pickfiles"
                                        className="button file-selector  wpuf_featured_image_4781"
                                        onClick={() => document.getElementById('featured-image-input').click()}
                                      >
                                        Featured Image
                                      </button>
                                      <input
                                        id="featured-image-input"
                                        type="file"
                                        accept="image/jpeg,.jpg,.jpeg,image/gif,.gif,image/png,.png,image/bmp,.bmp"
                                        style={{ display: 'none' }}
                                        onChange={(e) => handleFileChange(e, 'featuredImage')}
                                      />
                                      <ul className="wpuf-attachment-list thumbnails ui-sortable"></ul>
                                    </div>
                                  </div>
                                  <span className="wpuf-help">Upload the main image of your article</span>
                                </div>
                              </li>
                              <li className="wpuf-el tags" data-label="Tags">
                                <div className="wpuf-label">
                                  <label htmlFor="tags_4781">Tags <span className="required">*</span>
                                  </label>
                                </div>
                                <div className="wpuf-fields">
                                  <input
                                    className="textfield wpuf_tags_4781"
                                    name="tags"
                                    value={formData.tags}
                                    onChange={handleInputChange}
                                    placeholder="Enter tags separated by commas"
                                    data-required="yes"
                                    data-type="text"
                                    id="tags_4781"
                                    type="text"
                                  />
                                  <span className="wpuf-wordlimit-message wpuf-help"></span>
                                  <span className="wpuf-help">Separate tags with commas</span>
                                </div>
                              </li>
                              <li className="wpuf-submit">
                                <div className="wpuf-label"> &nbsp; </div>
                                <input type="hidden" id="_wpnonce" name="_wpnonce" value="dba7be9b45" />
                                <input type="hidden" name="_wp_http_referer" value="/submit-article/" />
                                <input type="hidden" name="form_id" value="4781" />
                                <input type="hidden" name="page_id" value="4795" />
                                <input type="hidden" name="wpuf_form_status" value="new" />
                                <input type="hidden" name="wpuf_form_revision" value="1" />
                                <input type="hidden" name="post_id" value="0" />
                                <input type="hidden" name="wpuf_form_gateway" value="bank" />
                                <input type="hidden" name="wpuf_form_total" value="0" />
                                <input type="hidden" name="wpuf_form_payment" value="post" />
                                <button disabled={loading} type="submit" name="submit" className="wpuf-submit-button">{loading ? "Submitting..." : "Submit Article"}</button>
                              </li>

                            </ul>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Submitarticle;
