import React from 'react';
import BlueKeyForm from './components/BlueKeyForm';

function ForgotPass() {
  return (
    <div>
      {/* <h1>Blue Key Recovery</h1> */}
      <BlueKeyForm />
    </div>
  );
}

export default ForgotPass;
