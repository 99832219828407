import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import '../css/alljobs.css'

const JobDetail = () => {
    const [job, setJob] = useState()
    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/jobes', { state: 'All Jobs' });
    };

    const videoUrl = job?.user?.user_company?.video;
    const videoId = videoUrl?.split('/')?.pop()?.split('?')[0];

    const fetchJob = async (page) => {
        try {
            var bearertoken = localStorage.getItem("Logtoken")
            const response = await fetch(`https://api.buzzinguniverse.iqspark.org/public/api/get-single-job/18}`, {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + bearertoken,
                    'Accept': 'application/json',
                },
            });
            const data = await response.json();
            setJob(data?.data)
            console.log(data, 'LLLLLLLLLLLLLLLLLLLLLLLLLLLLLL', response)
            // if (data?.status == 200) {
            //     setJobs(prevJobs => [...prevJobs, ...data?.data?.data])
            //     setDate(data?.data)
            // }
        } catch (error) {
            console.error('Error fetching groups:', error);
        }
    };

    useEffect(() => {
        fetchJob()
    }, []);


    const HtmlContent = ({ htmlString }) => {
        return (
            <div dangerouslySetInnerHTML={{ __html: htmlString }} />
        );
    };

    return (
        <>
            <Helmet>
                <link
                    rel="stylesheet"
                    href="https://buzzinguniverse.iqspark.org/css_file/alljobs.css"
                />
            </Helmet>
            <div id="content" className="site-content">
                <div id="primary" className="content-area">
                    <div className="layout social">
                        <div className="container-fluid">
                            <div style={{
                                width: "90%",
                                display: "flex",
                                justifyContent: "center"
                            }} className="row">
                                <div className="col-lg-8 col-main">
                                    <main id="main" className="main-content">

                                        <article id="post-61" className="post-61 page type-page status-publish hentry beehive-post">
                                            <div className="entry-content clearfix">
                                                <div className="job_listings" data-location="" data-keywords="" data-show_filters="true" data-show_pagination="false" data-per_page="30" data-orderby="featured" data-order="DESC" data-categories="" data-disable-form-state-storage="" data-post_id="61">
                                                    <ul className="job_listings">
                                                        <li className="animate-item slideInUp post-281 job_listing type-job_listing status-publish has-post-thumbnail hentry job_listing_type-full-time beehive-post job-type-full-time" data-longitude="" data-latitude="" style={{ visibility: "visible" }}>
                                                            <div className="job-list-item">
                                                                <div>
                                                                    <figure>
                                                                        <img className="company_logo" src={job?.user?.user_company?.logo} alt="Clinivex Analytics" />
                                                                    </figure>
                                                                </div>
                                                                <div className="job-info">
                                                                    <h4 className="job-title">
                                                                        <a href="/job/marketing-data-enrichment-specialist/">{job?.title}</a>
                                                                    </h4>
                                                                    <div className="about-company">
                                                                        <span className="address mute ellipsis">{job?.user?.user_company?.name}</span>
                                                                        <p className="company-name color-primary ellipsis">{job?.user?.user_company?.tagline}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="job-listing-meta">
                                                                    <ul className="job-types-lists ellipsis">
                                                                        <li className="job-type full-time">{job?.job_type}</li>
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                            <div style={{ padding: '10px' }}>
                                                                <div style={{ border: '1px solid #bbbbdc', borderRadius: '10px' }}>
                                                                    <div style={{ display: 'flex', borderBottom: '1px solid #bbbbdc', padding: '10px', color: '#bbbbdc', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                        <div>Job Type</div>
                                                                        <div>{job?.job_type}</div>
                                                                    </div>
                                                                    <div style={{ display: 'flex', padding: '10px', color: '#bbbbdc', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                        <div>Location</div>
                                                                        <div>{job?.location}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                <iframe
                                                                    width="600"
                                                                    height="400"
                                                                    src={`https://www.youtube.com/embed/${videoId}`}
                                                                    title="YouTube Video"
                                                                    frameBorder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                    allowFullScreen
                                                                ></iframe>
                                                            </div>
                                                            <div style={{ marginTop: '20px' }}>
                                                                <HtmlContent htmlString={job?.description} />
                                                            </div>
                                                            <div style={{ marginTop: '20px' }}>
                                                                <input title={`Email you documents on ${job?.application_preference} to apply for this job`} type="button" name="submit_job" className="button" value={'Apply For Job'} />
                                                                <input type="button" style={{marginLeft:'10px'}} name="submit_job" onClick={() => handleBack()} className="button" value={'Move Back'} />
                                                            </div>
                                                        </li>

                                                    </ul>

                                                </div>
                                            </div>

                                        </article>

                                    </main>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

            {/* Conditionally render the content based on the active tab */}
        </>
    );
};

export default JobDetail;
