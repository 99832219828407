import React from 'react';
import SubHeader from "./components/subheader";
import { Helmet } from 'react-helmet';
const PrivacyPolicy = () => {
    return (
        <>

            <Helmet>
                <link
                    rel="stylesheet"
                    href="https://buzzinguniverse.iqspark.org/css_file/termsofServices.css"
                />
            </Helmet>
           
            <div className="bp-nouveau page-template page-template-page-templates page-template-blank page-template-page-templatesblank-php page page-id-539 wp-custom-logo theme-beehive woocommerce-js beehive beehive-guest-user beehive-child elementor-default elementor-kit-32 elementor-page elementor-page-539 blank-template js js-hiraku-offcanvas-body e--ua-blink e--ua-chrome e--ua-webkit" data-elementor-device-mode="desktop">




                <div id="beehive-page" style={{ backgroundColor: "#000" }} className="site">


                    <header id="mastheadtop" className="site-header default-header user-nav-active">
                        <nav className="navbar beehive-navbar default fixed-top to-top">
                            <div className="container" style={{ margin: "0", flexDirection: "row" }}>
                                <div className="navbar-left">
                                </div>
                                <div className="navbar-right top-mehedi2">
                                    <a className="navbar-brand" href="https://buzzinguniverse.com/">
                                        <img src="https://buzzinguniverse.com/wp-content/uploads/2023/06/Logo.webp" title="BuzzingUniverse- Login or Signup" alt="BuzzingUniverse- Login or Signup" className="default-logo" />
                                    </a>
                                </div>
                            </div>
                        </nav>
                    </header>



                    <SubHeader />

                    <div id="content" className="site-content">



                        <div id="primary" className="content-area">
                            <div className="layout blank">
                                <div className="container">
                                    <div className="row">


                                        <div className="col-md-12 col-main">
                                            <main id="main" className="main-content">



                                            <article id="post-539" className="post-539 page type-page status-publish hentry beehive-post">
                                                    <div className="entry-content clearfix">
                                                        <div
                                                            data-elementor-type="wp-page"
                                                            data-elementor-id="539"
                                                            className="elementor elementor-539"
                                                            data-elementor-post-type="page"
                                                        >
                                                            <section
                                                                className="elementor-section elementor-top-section elementor-element elementor-element-db96ac5 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                                data-id="db96ac5"
                                                                data-element_type="section"
                                                            >
                                                                <div className="elementor-container elementor-column-gap-default">
                                                                    <div
                                                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1ee3cff"
                                                                        data-id="1ee3cff"
                                                                        data-element_type="column"
                                                                    >
                                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                                            <div
                                                                                className="elementor-element elementor-element-f1bb7b5 elementor-widget elementor-widget-heading"
                                                                                data-id="f1bb7b5"
                                                                                data-element_type="widget"
                                                                                data-widget_type="heading.default"
                                                                            >
                                                                                <div className="elementor-widget-container">
                                                                                    <h2 className="elementor-heading-title elementor-size-default">
                                                                                        Privacy Policy
                                                                                    </h2>
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                className="elementor-element elementor-element-7ace30b elementor-widget elementor-widget-text-editor"
                                                                                data-id="7ace30b"
                                                                                data-element_type="widget"
                                                                                data-widget_type="text-editor.default"
                                                                            >
                                                                                <div className="elementor-widget-container">
                                                                                    <p style={{ color: '#156e81' }}>
                                                                                        Buzzing Universe values your privacy and is committed to safeguarding your data. This Privacy Statement describes how we collect, use, disclose, and protect the information you provide using our social media platform, website, or mobile application. We encourage you to thoroughly read this Privacy Policy to understand how we handle your personal information.
                                                                                        <br />
                                                                                        Buzzing Universe is not intended for children under the age of 13. We do not knowingly collect personal information from children. If you believe we have inadvertently collected information from a child, please contact us, and we will take appropriate measures to remove the information.
                                                                                    </p>
                                                                                    <p>&nbsp;</p>
                                                                                    <p style={{ color: '#156e81' }}>
                                                                                        <strong>1. Personal Information:</strong><br />
                                                                                        When you create an account or use Buzzing Universe, we may collect certain personal information such as your name, email address, username, profile picture, and other information you voluntarily provide.
                                                                                    </p>
                                                                                    <p>&nbsp;</p>
                                                                                    <p style={{ color: '#156e81' }}>
                                                                                        <strong>2. Usage Information:</strong><br />
                                                                                        We may collect information about your usage of Buzzing Universe, including your interactions, posts, comments, likes, and communications. This information may include your device, IP address, browser type, and operating system.
                                                                                    </p>
                                                                                    <p>&nbsp;</p>
                                                                                    <p style={{ color: '#156e81' }}>
                                                                                        <strong>3. Cookies and Tracking Technologies:</strong>
                                                                                    </p>
                                                                                    <p style={{ color: '#156e81' }}>
                                                                                    We use cookies and similar monitoring technologies to enhance your experience on Buzzing Universe. These technologies collect information about your browsing activities, preferences, and other analytics data.
                                                                                    </p>
                                                                                    
                                                                                    <p>&nbsp;</p>
                                                                                    <p style={{ color: '#156e81' }}>
                                                                                        <strong>4. Registration and Account Security</strong>
                                                                                    </p>
                                                                                    <p style={{ color: '#156e81' }}>
                                                                                        <strong>a. Account Creation:</strong> You may be required to establish an account to
                                                                                        use certain features of the services. During the registration procedure, you agree to provide accurate and
                                                                                        complete information.
                                                                                    </p>
                                                                                    <p style={{ color: '#156e81' }}>
                                                                                        <strong>b. Account Security:</strong> You are responsible for keeping your account
                                                                                        credentials secure. Do not disclose your password or account access to others. Notify us immediately if you
                                                                                        suspect that your account has been compromised.
                                                                                    </p>
                                                                                    <p>&nbsp;</p>
                                                                                    <p style={{ color: '#156e81' }}>
                                                                                        <strong>5. Content Ownership and Licensing</strong>
                                                                                    </p>
                                                                                    <p style={{ color: '#156e81' }}>
                                                                                        <strong>a. User Content:</strong> You retain the proprietorship of any content you submit or post to the services. You grant Buzzing Universe a non-exclusive,
                                                                                        transferable, sub-licensable, royalty-free, worldwide license to use, reproduce, modify, distribute, exhibit,
                                                                                        and perform the User Content in connection with the provision of the Services by submitting User Content.
                                                                                    </p>
                                                                                    <p style={{ color: '#156e81' }}>
                                                                                        <strong>b. Buzzing Universe Content:</strong> Buzzing Universe or its licensors own all materials, logos, trademarks, and other
                                                                                        content provided by Buzzing Universe. You may not use, modify, or distribute Buzzing Universe content without
                                                                                        the prior written permission of Buzzing Universe.
                                                                                    </p>
                                                                                    <p>&nbsp;</p>
                                                                                    <p style={{ color: '#156e81' }}>
                                                                                        <strong>6. Termination</strong><br />
                                                                                        Buzzing Universe reserves the right to suspend or terminate your access to the services at any time, for any reason, or
                                                                                        no reason at all, and without prior notice. Additionally, you may cancel your account at any time by contacting us.
                                                                                    </p>
                                                                                    <p>&nbsp;</p>
                                                                                    <p style={{ color: '#156e81' }}>
                                                                                        <strong>7. Exclusion of Warranties</strong><br />
                                                                                        The services are offered on an “as is” and “as available” basis. Buzzing Universe makes no guarantee that the
                                                                                        services will be uninterrupted, error-free, or devoid of other potentially damaging components. We disclaim any
                                                                                        express or implied warranties, including but not limited to the implied warranties of merchantability, fitness
                                                                                        for a particular purpose, and non-infringement.
                                                                                    </p>
                                                                                    <p>&nbsp;</p>
                                                                                    <p style={{ color: '#156e81' }}>
                                                                                        <strong>8. Exclusion of Liability</strong><br />
                                                                                        Buzzing Universe shall not be liable for any direct, indirect, incidental, special,
                                                                                        consequential, or punitive damages arising out of or in connection with the use of or inability to use the
                                                                                        Services, even if advised of the possibility of such damages.
                                                                                    </p>
                                                                                    <p>&nbsp;</p>
                                                                                    <p style={{ color: '#156e81' }}>
                                                                                        <strong>9. Modifications of Terms and Conditions</strong><br />
                                                                                        Buzzing Universe reserves the right to alter these conditions at any time. Any material
                                                                                        modifications to these Terms will be communicated via the services. Use of the services following the
                                                                                        modifications will signify your acceptance of the updated Terms.
                                                                                    </p>
                                                                                    <p>&nbsp;</p>
                                                                                    <p style={{ color: '#156e81' }}>
                                                                                        <strong>10. Legal System and Jurisdiction</strong><br />
                                                                                        The laws of Jurisdiction shall govern and govern the interpretation of these Terms. The
                                                                                        courts of Jurisdiction shall have exclusive jurisdiction over any dispute originating from or relating to these
                                                                                        Terms.
                                                                                    </p>
                                                                                    <p>&nbsp;</p>
                                                                                    <p style={{ color: '#156e81' }}>
                                                                                        <strong>11. Account Termination</strong><br />
                                                                                        Buzzing Universe reserves the right to terminate and ban your account as a user at any
                                                                                        time for any reason if user doesn’t comply to the set Terms of Service (TOS).
                                                                                    </p>
                                                                                    <p>&nbsp;</p>
                                                                                    <p style={{ color: '#156e81' }}>
                                                                                        <strong>12. Dispute Resolution</strong><br />
                                                                                        Any disputes arising out of or relating to these terms of service will be resolved by binding arbitration under the
                                                                                        norms of the Arbitration Association.
                                                                                    </p>
                                                                                    <p style={{ color: '#156e81' }}>
                                                                                        Thank you for carefully reading the terms and conditions you should adhere to at Buzzing Universe! We hope you appreciate your visit and will assist us in building a thriving and positive online
                                                                                        community.
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                        </div>
                                                    </div>
                                                </article>






                                            </main>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>


                    <footer id="footer" className="site-footer default-footer">
                       
                        <div className="footer-menu-area">
                            <div className="container">
                                <div className="menu-area">
                                    <div className="footer-nav-menu company">
                                        <h4 className="menu-title">Company</h4>
                                        <ul id="menu-footer-menu-1" className="footer-menu-list"><li id="menu-item-542" className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-539 current_page_item menu-item-542"><a href="https://buzzinguniverse.com/terms-of-service/" aria-current="page">Terms of Service</a></li>
                                            <li id="menu-item-543" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-543"><a href="https://buzzinguniverse.com/privacy-policy/">Privacy Policy</a></li>
                                            <li id="menu-item-793" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-793"><a href="https://buzzinguniverse.com/about-us/">About Us</a></li>
                                            <li id="menu-item-5274" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5274"><a href="https://buzzinguniverse.com/contact/">Contact</a></li>
                                        </ul>						</div>
                                    <div className="footer-nav-menu community">
                                        <h4 className="menu-title">Community</h4>
                                        <ul id="menu-footer-menu-2" className="footer-menu-list"><li id="menu-item-133" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-133"><a href="https://buzzinguniverse.com/photos/">Photos</a></li>
                                            <li id="menu-item-134" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-134"><a href="https://buzzinguniverse.com/videos/">Videos</a></li>
                                            <li id="menu-item-131" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-131"><a href="https://buzzinguniverse.com/groups/">Groups</a></li>
                                            <li id="menu-item-130" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-130"><a href="https://buzzinguniverse.com/forums/">Forums</a></li>
                                            <li id="menu-item-4502" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4502"><a href="https://buzzinguniverse.com/articles/">Articles</a></li>
                                        </ul>						</div>
                                    <div className="footer-nav-menu useful-links">
                                        <h4 className="menu-title">Useful links</h4>
                                        <ul id="menu-footer-menu-3" className="footer-menu-list"><li id="menu-item-136" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-136"><a href="https://buzzinguniverse.com/adverts/">Classifieds</a></li>
                                            <li id="menu-item-137" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-137"><a href="https://buzzinguniverse.com/jobs/">Search jobs</a></li>
                                            <li id="menu-item-138" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-138"><a href="https://buzzinguniverse.com/post-a-job/">Post a job</a></li>
                                            <li id="menu-item-139" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-139"><a href="https://buzzinguniverse.com/adverts/add/">Post an ad</a></li>
                                        </ul>						</div>
                                    <div className="footer-nav-menu legal">
                                        <h4 className="menu-title">Legal</h4>
                                        <ul id="menu-footer-menu-4" className="footer-menu-list"><li id="menu-item-3554" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3554"><a href="https://buzzinguniverse.com/brand-policy/">Brand policy</a></li>
                                            <li id="menu-item-3560" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3560"><a href="https://buzzinguniverse.com/cookie/">Cookie</a></li>
                                        </ul>						</div>
                                </div>
                            </div>
                        </div>

                        <div id="colophon" className="colophon">
                            <div className="container">
                                <div className="copyright-text">
                                    <p>
                                        Buzzing Universe © 2024. All rights reserved.											</p>
                                </div>
                            </div>
                        </div>
                    </footer>

                </div>


                <div className="modal fade login-modal" id="login-modal" tabindex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="inner">
                                    <img src="https://buzzinguniverse.com/wp-content/uploads/avatar-privacy/cache/custom/1/131ecfcd673b203978922cbf323f9085bbba409c1277a70d6a489fffb7a202f4-96.png" alt="Guest" className="avatar guest-avatar" />
                                </div>
                            </div>
                            <div className="modal-body">
                                <h4 className="modal-title">access cube</h4>

                                <div id="login-progress" className="progress">
                                    <div className="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                                        <span className="percent">0%</span>
                                    </div>
                                </div>








                                <form action="https://buzzinguniverse.com/wp-login.php" method="post" id="modal-login-form" className="beehive-login-form modal-login-form" name="modal-login-form">
                                    <div className="form-group">
                                        <div className="user-name">
                                            <label className="screen-reader-text">Email/username</label>
                                            <span className="icon"><i className="uil-user"></i></span>
                                            <input type="text" id="modal-username" className="username-control" required="" name="log" value="" placeholder="username" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="pass">
                                            <label className="screen-reader-text">Password</label>
                                            <span className="icon"><i className="uil-key-skeleton-alt"></i></span>
                                            <input type="password" id="modal-password" className="password-control" required="" name="pwd" value="" placeholder="blue key" />
                                        </div>
                                    </div>
                                    <div className="modal-options">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="forgetmenot">
                                                    <label for="modal-rememberme">
                                                        <input id="modal-rememberme" name="rememberme" type="checkbox" value="forever" /> Remember									</label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="forgot-password">
                                                    <a href="https://buzzinguniverse.com/my-account/lost-password/">
                                                        Lost blue key?									</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="beehive-login-result"></div>
                                    <div className="submit">
                                        <button type="submit" id="modal_login_submit" className="submit-login" name="wp-submit">Login</button>
                                    </div>
                                    <input type="hidden" id="modal-login-security" name="modal-login-security" value="bf1fa8ba72" /><input type="hidden" name="_wp_http_referer" value="/terms-of-service/" />
                                    <div className="register-link">
                                        <a href="https://buzzinguniverse.com/wp-login.php?action=register" className="register color-primary">Create an account</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i className="icon ion-close-round"></i>
                    </button>
                </div>
            </div>
        </>
    );
};

export default PrivacyPolicy;
