import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Editor } from '@tinymce/tinymce-react';
import { ToastContainer, toast } from 'react-toastify';

function Advsubmit() {
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        adverts_person: "Shazzy",
        adverts_email: "Shazzy@yopmail.com",
        adverts_phone: "",
        post_title: "",
        advert_category: [],
        gallery: [],
        post_content: "",
        adverts_price: "",
        adverts_location: "",
        payments_listing_type: "2533",  // Default to "Free"
        website_address: ""
    });
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleEditorChange = (content) => {
        setFormData(prevState => ({
            ...prevState,
            post_content: content
        }));
    };

    const handleCheckboxChange = (e) => {
        const { name, value, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: checked
                ? [...prevState[name], value] // Add value if checked
                : prevState[name].filter(val => val !== value) // Remove value if unchecked
        }));
    };

    const handleRadioChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const validateForm = () => {
        // Check required fields
        const requiredFields = [
            'adverts_person',
            'adverts_email',
            'post_title',
            'post_content',
            'adverts_price',
            'adverts_location',
            'payments_listing_type'
        ];

        for (let field of requiredFields) {
            if (!formData[field] || formData[field].trim() === "") {
                alert(`Please fill in the ${field.replace('_', ' ').toUpperCase()}`);
                return false; // Return false to prevent form submission
            }
        }
        return true;
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        var bearertoken = localStorage.getItem("Logtoken")
        setLoading(true)
        const formDataToSubmit = new FormData();
        formDataToSubmit.append('contact_name', formData?.adverts_person);
        formDataToSubmit.append('contact_email', formData?.adverts_email);
        formDataToSubmit.append('phone_no', formData?.adverts_phone);
        formDataToSubmit.append('title', formData?.post_title);
        formDataToSubmit.append('category_id', '1');
        formDataToSubmit.append('description', formData?.post_content);
        formDataToSubmit.append('is_free' , '1')
        formDataToSubmit.append('location' , formData?.adverts_location)

        // Replace the single media append with a loop
        formData.gallery.forEach((file, index) => {
            formDataToSubmit.append(`media[${index}]`, file);
        });

        for (let [key, value] of formDataToSubmit.entries()) {
            console.log(`${key}: ${value}`);
        }
        if (validateForm) {
        try {
          const response = await fetch('https://api.buzzinguniverse.iqspark.org/public/api/adverts', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer '+bearertoken, 
              'Accept': 'application/json',
            },
            body: formDataToSubmit,
          });
          
          setLoading(false)
          const data = await response.json();
          if(data.status == 200 || data?.success)
          {
            toast.success("Advert submitted Successfully!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              window.location.reload();
          }
          else {
            throw new Error('Failed to post job');
          }
        } catch (error) {
            setLoading(false)
            console.error('Error:', error);
            throw new Error(JSON.stringify(error));
        }
        }
      };

      const handleFileChange = (e) => {
        const selectedFiles = e.target.files;
    
        const filesArray = Array.from(selectedFiles);
            setFormData(prevState => ({
            ...prevState,
            gallery: [...filesArray]
        }));
    };

    return (
        <>
        <ToastContainer />
            <Helmet>
                <link
                    rel="stylesheet"
                    href="https://buzzinguniverse.iqspark.org/css_file/advsubmit.css"
                />
            </Helmet>
            <article style={{
                width: "70%",
                margin: "0 auto",
                marginTop: "100px"
            }} id="post-469" className="post-469 page type-page status-publish hentry beehive-post">
                <div className="entry-content clearfix">
                    <form onSubmit={handleSubmit} className="adverts-form adverts-form-aligned">
                        <fieldset>
                            <div className="adverts-control-group adverts-field-header adverts-field-name-_contact_information">
                                <div className="adverts-field-header block-title">
                                    <h3 className="adverts-field-header-title">Contact Information</h3>
                                </div>
                            </div>
                            <div className="adverts-control-group adverts-field-account adverts-field-name-_adverts_account">
                                <label>Account</label>
                                <div>You are posting as <strong>
                                    <a href="/activity/">Shazzy</a>
                                </strong>. <br />If you want to use a different account, please <a href="/wp-login.php?action=logout&amp;_wpnonce=a802432ae5&amp;redirect_to=https%3A%2F%2Fbuzzinguniverse.com%2Fterms-of-service%2F%3Floggedout%3Dtrue">logout</a>. </div>
                            </div>
                            <div className="adverts-control-group adverts-field-text adverts-field-name-adverts_person">
                                <label>Contact Person <span className="adverts-form-required">*</span></label>
                                <input type="text" name="adverts_person" value={formData.adverts_person} onChange={handleInputChange} />
                            </div>
                            <div className="adverts-control-group adverts-field-text adverts-field-name-adverts_email">
                                <label>Email <span className="adverts-form-required">*</span></label>
                                <input type="email" name="adverts_email" value={formData.adverts_email} onChange={handleInputChange} />
                            </div>
                            <div className="adverts-control-group adverts-field-text adverts-field-name-adverts_phone">
                                <label>Phone Number</label>
                                <input type="text" name="adverts_phone" value={formData.adverts_phone} onChange={handleInputChange} />
                            </div>
                            <div className="adverts-control-group adverts-field-header adverts-field-name-_item_information">
                                <div className="adverts-field-header block-title">
                                    <h3 className="adverts-field-header-title">Item Information</h3>
                                </div>
                            </div>
                            <div className="adverts-control-group adverts-field-text adverts-field-name-post_title">
                                <label>Title <span className="adverts-form-required">*</span></label>
                                <input type="text" name="post_title" value={formData.post_title} onChange={handleInputChange} />
                            </div>
                            <div className="adverts-control-group adverts-field-select adverts-field-name-advert_category">
                                <label>Category</label>
                                <div className="adverts-multiselect-holder">
                                    <input type="text" id="advert_category" value={formData.advert_category.join(", ")} onChange={handleInputChange} placeholder="Select options ..." />
                                </div>
                            </div>
                            <div className="adverts-control-group adverts-field-gallery adverts-field-name-gallery">
    <label htmlFor="gallery">Gallery</label>
    <div id="adverts-plupload-upload-ui-gallery" className="adverts-plupload-upload-ui adverts-browser-frontend drag-drop">
        <div id="adverts-drag-drop-area-gallery" className="adverts-drag-drop-area"></div>
        <div className="adverts-gallery">
            <p>Drop files here to add them, or click the button below to browse files.</p>
            <p>
                <a href="#" id="adverts-plupload-browse-button-gallery" className="adverts-plupload-browse-button adverts-button">Browse files ...</a>
            </p>
        </div>
        <div className="adverts-gallery-uploads ui-sortable">
            {/* Here, you can display the uploaded files */}
            {formData.gallery.length > 0 && formData.gallery.map((file, index) => (
                <div key={index} className="adverts-uploaded-file">
                    <p>{file.name}</p>
                </div>
            ))}
        </div>
        <div id="html5_1i683ejrj1lpqf8j1rqu1te88v33_container" className="moxie-shim moxie-shim-html5">
            <input
                id="html5_1i683ejrj1lpqf8j1rqu1te88v33"
                type="file"
                multiple
                accept="image/*"
                onChange={handleFileChange}
            />
        </div>
    </div>
</div>
                            <div className="adverts-control-group adverts-field-textarea adverts-field-name-post_content">
                                <label>Description <span className="adverts-form-required">*</span></label>
                                <div id="wp-post_content-wrap" className="wp-core-ui wp-editor-wrap tmce-active">
                                    <Editor
                                        apiKey="6n6085y2zxnpx1z72tdi0n1cqo5x7zl2zv3z5xtxikdg5oo6"
                                        value={formData.post_content}
                                        init={{
                                            height: 400,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar:
                                                'undo redo | formatselect | bold italic backcolor | \
                                            alignleft aligncenter alignright alignjustify | \
                                            bullist numlist outdent indent | removeformat | help'
                                        }}
                                        onEditorChange={handleEditorChange}
                                    />
                                </div>
                            </div>
                            <div className="adverts-control-group adverts-field-text adverts-field-name-adverts_price">
                                <label>Price</label>
                                <input type="text" name="adverts_price" value={formData.adverts_price} onChange={handleInputChange} />
                            </div>
                            <div className="adverts-control-group adverts-field-text adverts-field-name-adverts_location">
                                <label>Location</label>
                                <input type="text" name="adverts_location" value={formData.adverts_location} onChange={handleInputChange} />
                            </div>
                            <div className="adverts-control-group adverts-field-header adverts-field-name-_listing_information">
                                <div className="adverts-field-header block-title">
                                    <h3 className="adverts-field-header-title">Listing Information</h3>
                                </div>
                            </div>
                            <div className="adverts-control-group adverts-payments-field-payment adverts-field-name-payments_listing_type">
                                <label>Listing <span className="adverts-form-required">*</span></label>
                                <div className="adverts-pricings-list">
                                    <div className="adverts-listing-type-x">
                                        <label className="adverts-cute-input adverts-cute-radio">
                                            <input
                                                name="payments_listing_type"
                                                className="adverts-listing-type-input"
                                                type="radio"
                                                value="2534"
                                                checked={formData.payments_listing_type === "2534"}
                                                onChange={handleRadioChange}
                                            />
                                            <div className="adverts-cute-input-indicator"></div>
                                        </label>
                                        <div className="adverts-listing-type-field">
                                            <div className="adverts-listing-type-name">
                                                <span className="adverts-listing-type-title">Premium</span>
                                            </div>
                                            <div className="adverts-listing-type-features">
                                                <span className="adverts-listing-type-feature-duration">
                                                    <span className="adverts-listing-type-icon adverts-icon-clock"></span> Visible 45 days
                                                </span>
                                            </div>
                                        </div>
                                        <span className="adverts-listing-type-cost"> $50.00 </span>
                                    </div>
                                    <div className="adverts-listing-type-x">
                                        <label className="adverts-cute-input adverts-cute-radio">
                                            <input
                                                name="payments_listing_type"
                                                className="adverts-listing-type-input"
                                                type="radio"
                                                value="2533"
                                                checked={formData.payments_listing_type === "2533"}
                                                onChange={handleRadioChange}
                                            />
                                            <div className="adverts-cute-input-indicator"></div>
                                        </label>
                                        <div className="adverts-listing-type-field">
                                            <div className="adverts-listing-type-name">
                                                <span className="adverts-listing-type-title">Free</span>
                                            </div>
                                            <div className="adverts-listing-type-features">
                                                <span className="adverts-listing-type-feature-duration">
                                                    <span className="adverts-listing-type-icon adverts-icon-clock"></span> Visible 30 days
                                                </span>
                                            </div>
                                        </div>
                                        <span className="adverts-listing-type-cost"> Free </span>
                                    </div>
                                </div>
                            </div>
                            <div className="adverts-control-group adverts-field-text adverts-field-name-website_address">
                                <label>Website Address</label>
                                <input
                                    type="text"
                                    name="website_address"
                                    value={formData.website_address}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="adverts-control-group submit adverts-field-actions">
                                <input type="submit" value={loading ? 'Loading...' : 'Submit'} disabled={loading} className="adverts-cancel-unload medium" />
                            </div>
                        </fieldset>
                    </form>
                </div>
            </article>
        </>
    );
}

export default Advsubmit